import React, { Component, Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate, useParams } from 'react-router-dom'
import _ from 'lodash'
import { handleError } from '@/shared/api'
import { showToast } from '@/shared/utils/toast'
import { formatPrice } from '@/shared/utils/numbers'
import * as api from '@/book/api'
import { Modal, Field, Button } from '@/shared/components'
import '@/book/styles/createBooking.scss'

const propTypes = {
  rates: PropTypes.object,
  trackEvent: PropTypes.func.isRequired,
  query: PropTypes.object.isRequired,
  isImportingBooking: PropTypes.bool.isRequired
}

const c = 'book_createBooking'

const CreateBooking = (props) => {

  const { listingId } = useParams()
  const navigate = useNavigate()

  const [state, setState] = useState({
    isBooking: false,
    referralCode: '',
    total: '',
    utmUrl: '',
    utmSource: '',
    utmMedium: '',
    utmCampaign: '',
    utmTerm: '',
    utmContent: ''
  })

  const createBooking = () => {
    if (props.query.programSessionId) {
      createProgramSessionBooking()
    } else if (props.isImportingBooking) {
      if (!state.total.trim()) {
        return showToast({
          type: 'danger',
          title: 'Please enter a valid grand total amount.',
        })
      }
      createImportedBooking()
    } else {
      createNormalBooking()
    }
    setState(prevState => ({
      ...prevState,
      isBooking: true
    }))
  }

  const createNormalBooking = () => api.createBooking(
    listingId, {
      ...getBasePayload(),
      referralCode: state.referralCode || undefined,
      utmUrl: state.utmUrl,
      utmSource: state.utmSource,
      utmMedium: state.utmMedium,
      utmCampaign: state.utmCampaign,
      utmTerm: state.utmTerm,
      utmContent: state.utmContent,
    }
  ).then(onSuccess, onError)

  const createProgramSessionBooking = () => api.createProgramSessionBooking(
    props.query.programSessionId,
    listingId,
    getBasePayload()
  ).then(onSuccess, onError)

  const createImportedBooking = () => api.createImportedBooking({
    ...getBasePayload(),
    listingId: listingId,
    total: state.total || undefined,
  }).then(onSuccess, onError)

  const getBasePayload = () => _.pick(
    props.query, ['startDate', 'endDate', 'additionalFees', 'userId']
  )

  const onSuccess = () => {
    props.trackEvent('Booking Wizard Request Submitted')
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      'event': 'booking-success',
    })
    navigate('/book-success')
  }

  const onError = ({ error }) => {
    let errorMessage;

    if (error.code === 'not_found') {
      errorMessage = 'This listing is no longer available.'
      showToast({
        type: 'danger',
        title: errorMessage,
        message: 'Someone probably booked it just moments ago.',
        duration: 0,
      })
    } else if (error.code === 'invalid_resource') {
      errorMessage = error.details.base
      showToast({
        type: 'danger',
        title: errorMessage,
        duration: 0,
      })
    } else {
      errorMessage = _.get(error, 'details.base') || error.description
      handleError({ error })
    }
    props.trackEvent('Booking Wizard Request Submitted', {
      error: errorMessage || 'true',
    })
    setState(prevState => ({
      ...prevState,
      isBooking: false
    }))
  }

  const hasCharges = !!props.rates.charges.length
  const { isImportingBooking, query } = props

  return (
    <div className={c}>
      {!query.programSessionId && !isImportingBooking && hasCharges && (
        <Field.Input
          size="small"
          label="Enter referral code"
          value={state.referralCode}
          onChange={(e, referralCode) => setState(prevState => ({
            ...prevState,
            referralCode: referralCode
          }))}
        />
      )}
      {isImportingBooking && (
        <Field.Input
          size="small"
          icon="dollar"
          filter={/^[0-9]*$/}
          label="Grand Total"
          value={state.total}
          onChange={(e, total) => setState(prevState => ({
            ...prevState,
            total: total
          }))}
        />
      )}
      <input type="hidden" name="utm_url" value={state.utmUrl} />
      <input type="hidden" name="utm_source" value={state.utmSource} />
      <input type="hidden" name="utm_medium" value={state.utmMedium} />
      <input type="hidden" name="utm_campaign" value={state.utmCampaign} />
      <input type="hidden" name="utm_term" value={state.utmTerm} />
      <input type="hidden" name="utm_content" value={state.utmContent} />
      {hasCharges && !isImportingBooking ? (
        <Modal
          className={`${c}_confirmModal`}
          renderLink={() => (
            <Button size="large" working={state.isBooking}>
              Send Booking Request
            </Button>
          )}
          renderContent={({ closeModal }) => (
            <Fragment>
              <h3>{`If the host accepts your booking, you will be charged ${formatPrice(props.rates.charges[0].total)} immediately.`}</h3>
              <p>{`Before proceeding with your request, please make sure you have taken the following steps to insure a successful transaction:`}</p>
              <p><span>{`1) Contact your bank at the number listed on the back of your card to inform them of a charge in this amount. Sometimes banks will mistakenly flag larger transactions as fraudulent and decline the charges.`}</span></p>
              <p><span>{`2) Insure that your available credit or debit balance is enough to cover this, and any other, Roomsie related charges or fees.`}</span></p>
              <p>{`Failure to follow these two simple steps can, and may, result in the cancellation of your reservation.`}</p>
              <Button hollow onClick={() => {
                closeModal()
                createBooking()
              }}>
                I understand. Send my booking request!
              </Button>
            </Fragment>
          )}
        />
      ) : (
        <Button size="large" working={state.isBooking} onClick={createBooking}>
          Send Booking Request
        </Button>
      )}
    </div>
  )
}

CreateBooking.propTypes = propTypes

export default CreateBooking